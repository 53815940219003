
import { PropOptions } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { TProduct, TTab } from './types'
import ProductListTabs from './product-list-tabs.vue'
import get from '~/utils/get'
import isEmpty from '~/utils/isEmpty'
import { MainTitle, ProductCard, BrandBox } from '~/components/molecules'
import { ScamModal } from '~/components/organisms'
import { Row, Column, Container } from '~/components/grid'
import { IProduct } from '~/models/components/Product'
import { REDEEM_TYPE, PRODUCT_KIND } from '~/services/topup/enums'
import { CdpContainer } from '~/components/renderers'
import { CDP_PAGE_TYPES } from '~/enums/customer-data-platform'

function sortByAvailable(products) {
  return [...products].sort((a, b) => Number(b.available) - Number(a.available))
}

export default {
  components: {
    CdpContainer,
    MainTitle,
    ProductCard,
    BrandBox,
    Row,
    Column,
    Container,
    ProductListTabs,
    ScamModal,
  },
  props: {
    products: {
      type: Array,
      default: () => [],
    } as PropOptions<IProduct[]>,
    disablePurchase: {
      type: Boolean,
      default: false,
    },
    brandInfo: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      default: '',
    },
    maxLen: {
      type: Number,
      default: 99,
    },
    addListToCartTracking: {
      type: Boolean,
      default: false,
    },
    phoneNumberInfo: {
      type: Object,
      default: () => ({}),
    },
    selectedCurrency: {
      type: Object,
      default: () => ({}),
    },
    orderedTabs: {
      type: Array,
      default: null,
    },
    cdpInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      productsImpressedByCurrency: [],
      scamModalVisible: false,
      product: null,
    }
  },

  computed: {
    ...mapGetters('contentful', ['country']),
    ...mapGetters('context', [
      'useRecommerce',
      'currency',
      'isGlobalMarketplace',
    ]),
    ...mapGetters('products', ['all', 'someProductsHaveRTRRedeemType']),
    ...mapGetters('ui', ['overlayVisibility', 'overlayName']),
    currencyCode() {
      return get(this?.country, 'currencies[0].abv', '')
    },
    id() {
      return (this.title || '').toLowerCase().replace(/[\s&]+/g, '_')
    },
    exceedMaxLen(): boolean {
      return this.maxLen > 0 && this.products.length >= this.maxLen
    },
    combinedProducts(): TProduct[] {
      const { products, all: topupProducts } = this

      return products.reduce((accumulator, cmsProduct) => {
        const product = {
          ...cmsProduct,
          ...topupProducts[cmsProduct.productId],
        }

        if (!product.price) return accumulator

        const nextIndex = accumulator.findIndex(
          ({ price: { localAmount = 0 } = {} }) =>
            product.price.localAmount < localAmount
        )
        const index = nextIndex > -1 ? nextIndex : accumulator.length

        accumulator.splice(index, 0, product)

        return accumulator
      }, [])
    },
    currencyFilteredProducts(): TProduct[] {
      return this.combinedProducts.filter(
        product =>
          !this.selectedCurrency?.value ||
          this.selectedCurrency.value === product.priceCurrency
      )
    },
    tabs(): TTab[] {
      const { combinedProducts } = this

      const tabsByKind = combinedProducts.reduce((accumulator, product) => {
        const { product_kind: productKind } = product
        const productsByKind = (accumulator[productKind] || {}).products || []
        productsByKind.push(product)

        if (PRODUCT_KIND[productKind.toUpperCase()] === undefined) {
          return {}
        }
        const currenciesByKind =
          (accumulator[productKind] || {}).currencies || []
        if (!currenciesByKind.includes(product.priceCurrency))
          currenciesByKind.push(product.priceCurrency)

        return {
          ...accumulator,
          [productKind]: {
            kind: productKind,
            name: this.$t(`organisms.product_list.tabs.${productKind}`),
            products: productsByKind,
            currencies: currenciesByKind,
          },
        }
      }, {})

      let position = 0
      const tabs = Object.values(tabsByKind).map((tab: TTab) => {
        const products = tab.products.map(product => {
          position += 1
          return { ...product, position }
        })
        return { ...tab, products }
      })

      // sorts according to order tab position, if found in orderdTabs object, otherwise put the element in last position
      tabs.sort((tab1, tab2) => {
        let order1 = this.orderedTabs?.findIndex(tab => tab.type === tab1.kind)
        order1 = order1 < 0 ? tabs.length + 1 : order1
        let order2 = this.orderedTabs?.findIndex(tab => tab.type === tab2.kind)
        order2 = order2 < 0 ? tabs.length + 1 : order2
        return order1 - order2
      })

      return tabs
    },
    limitedProducts(): TProduct[] {
      if (this.tabs.length) {
        const tabCurrencyFilteredProducts = this.tabs[0].products?.filter(
          product =>
            !this.selectedCurrency?.value ||
            this.selectedCurrency.value === product.priceCurrency
        )
        return sortByAvailable(tabCurrencyFilteredProducts)
      }
      const { exceedMaxLen, maxLen, currencyFilteredProducts } = this
      return exceedMaxLen
        ? sortByAvailable(currencyFilteredProducts).slice(0, maxLen)
        : sortByAvailable(currencyFilteredProducts)
    },
    showBrandInfoBox(): boolean {
      const { exceedMaxLen, brandInfo } = this
      const hasNotBrandInfo = !isEmpty(brandInfo)
      return exceedMaxLen && hasNotBrandInfo
    },
    cdpContainerPageType(): string {
      if (this.$route.name === 'variant') {
        return CDP_PAGE_TYPES.PRODUCT_VARIANT
      }
      return CDP_PAGE_TYPES.MULTIPLE_CARDS
    },
    cdpContainerProductType(): string {
      if (this.someProductsHaveRTRRedeemType) {
        return REDEEM_TYPE.RTR
      }
      return REDEEM_TYPE.PIN
    },
    cdpContainerDesktopConfig() {
      const productsQtt = this.limitedProducts.length

      if (productsQtt >= 3)
        return {
          class: 'w-full',
          columns: 3,
        }

      if (productsQtt === 2)
        return {
          class: 'w-full sm:w-1/2 lg:w-1/3',
          columns: 1,
        }

      if (productsQtt === 1)
        return {
          class: 'w-full sm:w-1/2 lg:w-2/3',
          columns: 2,
        }

      return {
        class: '',
        columns: 0,
      }
    },
  },
  watch: {
    selectedCurrency() {
      // trigger impression for currency change when page does not have tabs
      if (this.tabs && this.tabs.length) return
      this.trackProductImpressions(this.limitedProducts)
    },
  },
  mounted() {
    this.validateLastProductSeen()
    const currentTab = this.tabs[0]
    this.$emit('tab-change', currentTab)
    if (this.tabs.length) this.trackProductImpressions(this.limitedProducts)
  },
  methods: {
    ...mapActions('checkout', ['saveProduct']),
    ...mapActions('context', ['validateLastProductSeen']),
    handleProductCardClick(product): void {
      const shouldShowScamModal = Boolean(product.scamNotificationType)
      if (shouldShowScamModal) {
        this.product = product
        this.scamModalVisible = true
      } else {
        this.handleProductBuy(product)
      }
    },
    handleProductBuy(item): void {
      const product = item || this.product
      const { redeemType } = product

      const { phoneNumber = '', encodedPhoneNumber = '' } = this.phoneNumberInfo
      if (redeemType === REDEEM_TYPE.RTR && !phoneNumber) {
        this.$emit('product-click', product)
        return
      }

      this.redirectToCheckout(product, encodedPhoneNumber)
    },
    handleTabChange(tab) {
      const { kind } = tab
      this.$gtmEnhanced.trackProductTabChange(kind)
      this.$emit('tab-change', tab)
    },
    onPhoneEdit() {
      this.$emit('phone-edit')
    },
    redirectToCheckout(product, encodedPhoneNumber): void {
      const { quantity, productId } = product

      let checkoutUrl = `/checkout?productId=${productId}&quantity=${quantity}`
      if (encodedPhoneNumber) {
        checkoutUrl = `${checkoutUrl}&p=${encodedPhoneNumber}`
      }

      this.trackAddToCart(product, this.addListToCartTracking)
      window.location.href = this.$contextPath(checkoutUrl)
    },
    trackAddToCart(product, addList) {
      const { currency } = this

      this.$gtmEnhanced.trackAddToCart(product, currency, addList)
    },

    trackProductImpressions(products) {
      if (
        this.productsImpressedByCurrency.includes(this.selectedCurrency?.value)
      )
        return

      const { currency } = this

      this.productsImpressedByCurrency.push(this.selectedCurrency?.value)

      if (this.isGlobalMarketplace) {
        this.$gtmEnhanced.trackGmpProductImpressions(products, currency)
      } else {
        this.$gtmEnhanced.trackProductImpressions(products, currency)
      }
    },
    closeScamModal() {
      this.scamModalVisible = false
    },
    cdpContainerId(device): string {
      if (this.$route.name === 'variant') {
        return `cdp-product-products-${device}-card`
      }
      return `cdp-brand-products-${device}-card`
    },
  },
}
