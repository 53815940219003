
import { PropOptions } from 'vue'
import { TTab } from './types'
import { Row, Column, Container } from '~/components/grid'
import { ProductCard, TabList, PhoneNumberBox } from '~/components/molecules'
import { getStorageItem } from '~/services/localstorage'
import { IProduct } from '~/models/components/Product'
import { CdpContainer } from '~/components/renderers'
import { CDP_PAGE_TYPES } from '~/enums/customer-data-platform'
export default {
  components: {
    CdpContainer,
    TabList,
    ProductCard,
    PhoneNumberBox,
    Row,
    Column,
    Container,
  },
  props: {
    uniquePhoneKey: {
      type: String,
      default: '',
    },
    tabs: {
      type: Array,
      default: () => [],
    } as PropOptions<TTab[]>,
    showPhoneNumberBox: Boolean,
    selectedCurrency: {
      type: Object,
      default: () => ({}),
    },
    products: {
      type: Array,
      default: () => [],
    } as PropOptions<IProduct[]>,
    cdpInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      selectedTab: this.tabs[0],
      savedPhoneNumber: '',
      tabsCurrencyImpressedByKind: [],
    }
  },
  computed: {
    currencyFilteredProductTabs() {
      return this.tabs.map(tab => {
        const products = this.products.filter(
          product =>
            (!this.selectedCurrency?.value ||
              this.selectedCurrency.value === product.priceCurrency) &&
            product.product_kind === tab.kind
        )
        return { kind: tab.kind, products }
      })
    },
    cdpPageType() {
      return CDP_PAGE_TYPES.MULTIPLE_CARDS
    },
    cdpContainerDesktopConfig() {
      const productsQtt = this.tabProducts().length

      if (productsQtt >= 3)
        return {
          class: 'w-full',
          columns: 3,
        }

      if (productsQtt === 2)
        return {
          class: 'w-full sm:w-1/2 lg:w-1/3',
          columns: 1,
        }

      if (productsQtt === 1)
        return {
          class: 'w-full sm:w-1/2 lg:w-2/3',
          columns: 2,
        }

      return {
        class: '',
        columns: 0,
      }
    },
  },
  watch: {
    selectedCurrency() {
      if (
        !this.tabsCurrencyImpressedByKind.includes(
          `${this.selectedCurrency?.label}-${this.selectedTab.kind}`
        )
      ) {
        const products = this.tabProducts()

        this.trackProductImpressions(products, this.selectedCurrency)
        this.tabsCurrencyImpressedByKind.push(
          `${this.selectedCurrency?.label}-${this.selectedTab.kind}`
        )
      }
    },
  },
  mounted() {
    const { phoneNumberInfo: { phoneNumber = '' } = {} } = getStorageItem({
      storage: 'recharge-store',
    })
    this.savedPhoneNumber = phoneNumber
  },
  methods: {
    async handleTabChange(tab) {
      await this.moveCdpContent(false)
      await this.$emit('tab-change', tab)
      this.moveCdpContent(true)
    },
    handleProductCardClick(product): void {
      this.$emit('product-list-tabs:click', product)
    },
    onPhoneEdit(): void {
      this.$emit('phone-edit')
    },
    trackProductImpressions(products, currency) {
      if (this.isGlobalMarketplace) {
        this.$gtmEnhanced.trackGmpProductImpressions(products, {
          abv: currency?.label,
        })
      } else {
        this.$gtmEnhanced.trackProductImpressions(products, {
          abv: currency?.label,
        })
      }
    },
    cdpContainerId(device): string {
      if (this.$route.name === 'variant') {
        return `cdp-product-products-${device}-card`
      }
      return `cdp-brand-products-${device}-card`
    },
    tabProducts() {
      const products = this.products.filter(
        product =>
          (!this.selectedCurrency?.value ||
            this.selectedCurrency.value === product.priceCurrency) &&
          product.product_kind === this.selectedTab.kind
      )

      return products
    },
    moveCdpContent(showContainer: boolean) {
      const selectorVal = showContainer
        ? '.products-wrapper'
        : '#cdp-hidden-reference'
      const destination = document.querySelector(selectorVal)!
      const mobileCdpContainer = document.getElementById(
        this.cdpContainerId('mobile')
      )
      const desktopCdpContainer = document.getElementById(
        this.cdpContainerId('desktop')
      )

      if (mobileCdpContainer) {
        destination.appendChild(mobileCdpContainer)
      }

      if (desktopCdpContainer) {
        destination.appendChild(desktopCdpContainer)
      }
    },
  },
}
